import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ProductSelection.css';
import logo from '../../assets/images/logo.png'; // Ensure this path is correct
import Mounjaro from '../../assets/images/product-card-maunjaro.webp';
import Wegovy from '../../assets/images/product-card-wegovy.webp';
import { post,get } from '../../services/ApiService';
import { addToCart } from '../../redux/slices/CartSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const ProductSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingCart, setIsAddingCart] = useState(false);
  const [products, setProducts] = useState([]);

  const handleProductSelect = (product, variation) => {
    if (selectedProduct?._id === product._id && selectedVariation?._id === variation._id) {
      // Deselect if clicking the same variation
      setSelectedProduct(null);
      setSelectedVariation(null);
    } else {
      setSelectedProduct(product);
      setSelectedVariation(variation);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await get('api/products/by/weight-loss');
      if (res.success) {
        setProducts(res.products);

      } else {
        setProducts([]);
        setNotFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      setProducts([]);
      setNotFound(true);
      setIsLoading(false);
    }
  };

    useEffect(() => {
      fetchData();
    }, []);

    const AddingToCart = () => {
      try {
        setIsAddingCart(true);
        
        const product = {
          _id: selectedProduct._id,
          name:  selectedProduct.name,
          unique_code: "",
          price: selectedVariation.price,
          serviceType: 'service',
          type: "",
          testtype: 'weight-loss',
          variation:selectedVariation,
          serviceId:selectedVariation?.serviceId,
          serviceAdonsId:""
        };
    
        dispatch(addToCart(product));

        navigate('/checkout');
      } catch (error) {
        console.error(error);
        message.error('Failed to add to cart');
      } finally {
        setIsAddingCart(false);
      }
  };

  return (
    <div className="product-selection-page">
      {/* Header */}
      <header className="product-header">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="center-logo" />
        </div>
      </header>

      {/* Main Content */}
      <section className="weight-loss-products">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">OUR PRODUCTS FOR WEIGHT LOSS</h1>  
            </div>
          </div>
          <div className="row justify-content-center flex-nowraps">
            {products.map((product) => (
              <div key={product.id} className="col-xxl-4 col-lg-5 col-md-6">
                <div className="product-card">
                  <div className="product-image">
                    <img src={product.name === "Mounjaro" ? Mounjaro : Wegovy} alt={product.name} />
                  </div>
                  <div className="product-content">
                    <h3>{product.name}</h3>
                    <p className="product-description">{product.description}</p>
                    {selectedProduct?._id === product._id && selectedVariation && (
                      <div className="product-pricing mb-8">
                        <div className="price-block">
                          <span className="label text-center">FIRST MONTH:</span>
                          <span className="cricle-amount">
                            <h4 className="text-white text-center m-0 fs-25">£{selectedVariation.price}</h4>
                          </span>
                        </div>
                      </div>
                    )}
                    
                    <div className="product-tags">
                      {product.variations.map((variation) => (
                        <button
                          key={variation._id}
                          className={`tag ${
                            selectedProduct?._id === product._id && 
                            selectedVariation?._id === variation._id ? 'active' : ''
                          }`}
                          onClick={() => handleProductSelect(product, variation)}
                        >
                          {variation.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Fixed Checkout Bar */}
      <div className="checkout-bar">
        <div className="selected-product">
          {selectedProduct && selectedVariation && (
            <div className="selection-info">
              <img src={selectedProduct.name === "Mounjaro" ? Mounjaro : Wegovy} alt={selectedProduct.name} />
              <div className="d-flex flex-wrap">
              <span>{selectedProduct.name} - {selectedVariation.name}</span>
              <span className='w-100'>£{selectedVariation.price}</span>
              </div>
              </div>
          )}
        </div>
        <button 
          className={`checkout-btn ${!selectedVariation ? 'disabled' : ''}`}
          disabled={!selectedVariation}
          onClick={()=>(AddingToCart())}
        >
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default ProductSelection; 