import { createSlice } from '@reduxjs/toolkit';

const bmiSlice = createSlice({
  name: 'bmi',
  initialState: {
    heightCm:"0",
    heightFeet: "0",
    heightInches: "0",
    weightKg:"0",
    weightStones: "0",
    weightPounds: "0",
    bmiCategory: null,
    bmiValue: 0,
  },
  reducers: {
    setBmiValues: (state, action) => {
      state.heightFeet = action.payload.heightFeet;
      state.heightInches = action.payload.heightInches;
      state.weightStones = action.payload.weightStones;
      state.weightPounds = action.payload.weightPounds;
      state.bmiCategory = action.payload.bmiCategory;
      state.bmiValue = action.payload.bmiValue;
    },
    resetBmiValues: (state) => {
      state.heightFeet = "0";
      state.heightInches = "0";
      state.weightStones = "0";
      state.weightPounds = "0";
      state.bmiCategory = null;
      state.bmiValue = 0;
    },
  },
});

export const { setBmiValues, resetBmiValues } = bmiSlice.actions;
export default bmiSlice.reducer;