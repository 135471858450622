import React, { useEffect, useState } from "react";
import { CardItem } from "./CardItem.jsx";
import { DrawerBiomarker } from "./BiomarkerDrawer.jsx";
import { get, post } from '../../../services/ApiService.js';
import Slider from "react-slick";

const TabAzTest = () => {
  const [visible, setVisible] = useState(false);
  const [biomarkerData, setBiomarkers] = useState([]);
  const [selectedBiomarkers, setSelectedBiomarkers] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [isLoad, setIsLoad] = useState(true);
  const [ clickactive, setClickActive ] = useState(0)

  var tabsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    autoplay: false,
    arrows: true,
    nextArrow: (
      <div>
        <img src="/assets/icons/next.png" width={25} />
      </div>
    ),
    prevArrow: (
      <div>
        <img src="/assets/icons/prev.png" width={25} />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const handleTypeChange = (value) => {
    setSelectedServiceType(value);
    setIsDataLoading(true);

  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoad(true);
      try {
        await fetchData();
        await fetchBiomarkers();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoad(false);
      }
    };

    if (selectedServiceType && selectedServiceType !== "") {
      loadData();
    }
  }, [selectedServiceType]);

  useEffect(() => {
    setSelectedServiceType("all");
    fetchData();
  }, []);

  const fetchBiomarkers = async () => {
    try {
      const res = await get("api/package/biomarkers");
      if (res.success) {
        setBiomarkers(res.biomarkers);
      } else {
        setBiomarkers([]);
      }
    } catch (error) {
      setBiomarkers([]);
    }
  };

  const fetchData = async () => {
    try {
      setIsDataLoading(true);
      setNotFound(false);
      const res = await post("api/package/data", {
        type: selectedServiceType,
        search: searchValue,
      });
      
      if (res.success) {
        setData(res.data);
        setFilteredData(res.data); 
      } else {
        setData([]);
        setFilteredData([]); 
        setNotFound(true);
      }
      setIsDataLoading(false);
    } catch (error) {
      setData([]);
      setFilteredData([]); 
      setNotFound(true);
      setIsDataLoading(false);
    }
  };



  const categoryTab = [
    {
      id: 1,
      imgdef:'alltest-b.png',
      imgactive:'alltest-w.png',
      heading: 'All Tests',
    },
    {
      id: 2,
      imgdef:'heartpulse-b.png',
      imgactive:'heartpulse-w.png',
      heading: 'General Health Tests',
    },
    {
      id: 3,
      imgdef:'user-b.png',
      imgactive:'user-w.png',
      heading: 'Mens Health',
    },
    {
      id: 4,
      imgdef:'smile-b.png',
      imgactive:'smile-w.png',
      heading: 'Mood Tests',
    },
    {
      id: 5,
      imgdef:'apple-b.png',
      imgactive:'apple-w.png',
      heading: 'Nutrition',
    },
    {
      id: 6,
      imgdef:'hold-b.png',
      imgactive:'hold-w.png',
      heading: 'Sexual Health/Fertility',
    },
    {
      id: 7,
      imgdef:'fitness-b.png',
      imgactive:'fitness-w.png',
      heading: 'Sports/Fitness',
    },
    {
      id: 8,
      imgdef:'tired-b.png',
      imgactive:'tired-w.png',
      heading: 'Tiredness & Fatigue Tests',
    },
    {
      id: 9,
      imgdef:'weight-b.png',
      imgactive:'weight-w.png',
      heading: 'Weight',
    },
    {
      id: 10,
      imgdef:'women-b.png',
      imgactive:'women-w.png',
      heading: "Women's Health",
    },
  ]

  return (
    <div className="pt-lg-17 pt-10">
      <ul
        className="nav-pills mb-3 w-100 pill-cust hoverarrow p-0"
        id="pills-tab"
        role="tablist"
      >
        <Slider {...tabsSlider}>
          {
            categoryTab?.map((cate,index)=>
            <li className="nav-item flex-grow-1 slickli h-100" role="presentation" key={index}>
              <button
                className={`nav-link w-100 d-flex flex-column align-items-center ${clickactive === index ? 'active': ''}`}
                type="button"
                onClick={() => clickactive !== index && setClickActive(index)}
              >
                <span>
                  <img
                    src={"/assets/icons/"+cate?.imgdef}
                    className="icon-b"
                    width={30}
                    alt=""
                  />
                  <img
                    src={"/assets/icons/"+cate?.imgactive}
                    className="icon-w"
                    width={30}
                    alt=""
                  />
                </span>
                <span className="metropill pt-5 pb-3">{cate?.heading}</span>
              </button>
            </li>
            )
          }
        </Slider>
      </ul>
      <div className="tab-content">
        <div>
          <CardItem 
            data={filteredData}
            isNotFound={isNotFound} 
            selectedServiceType={selectedServiceType}
            setSearchValue={setSearchValue}  
            searchValue ={searchValue} 
            handleTypeChange={handleTypeChange} 
            biomarkerData={biomarkerData}
            isLoad={isLoad}
            categoryTab={categoryTab}
            clickactive={clickactive}
          />
        </div>
      </div>
      
    </div>
  );
};

export { TabAzTest };
