import React, { useState, useEffect, useRef } from "react";
import { post } from '../../services/ApiService.js';
import TimeSlotList from "./TimeSlots";
import BillingDetail from "./BillingDetail.jsx";
import BookingDatePicker from "./BookingDatePicker.jsx";
import OrderSummary from "./OrderSummary.jsx";
import PageLoader from "../../components/PageLoader.js";
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { NewNavbar, BreadCrumbsNew, NewFooter } from '../../components';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Checkout = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const isBloodDraw = cartItems.some((item) => item.testtype === "bloodtest");
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(true);
  const containerRef = useRef(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isTimeSlotErr, setIstimeSlotErr] = useState(false);
  const timeSlotsRef = useRef(null);
  const [userToken, setUserToken] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [filteredTimeSlots, setFilteredTimeSlots] = useState([]);
  const [consistentTimeSlots, setConsistentTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [dateSelectLoading, setDateSelectLoading] = useState(false);
  const [isOver18, setIsOver18] = useState(true);
  const [inputErrors, setInputErrors] = useState({});
  const firstErrorRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nineDigitRegex = /^\d{9,}$/;
  // const stripePromise = loadStripe('pk_test_51IjhJTFHLnGWPWc3sdTdCkcNo3pBg55BaWV6M9oOkv6z9KSe1aZdvVKtVXICL13G1m3GbnevZTlY3qLOQ26yzCfo00H53DuljF');

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    date: '',
    time: '',
    slots: [],
    userToken: ''
  });

  useEffect(() => {
    filterTimeSlots();
  }, [availableTimeSlots]);


  useEffect(() => {

    if (startDate && cartItems) {
      const formattedDate = startDate.toISOString().slice(0, 10);
      fetchTimeSlots(formattedDate);
    }
  }, [startDate, cartItems]);


  const calculateTotalDuration = () => {
    const filteredServices = cartItems.filter(service => service.type === "Service");

    const totalDuration = filteredServices.reduce((total, service) => {
      return total + Number(service.duration);
    }, 0);

    return totalDuration;

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setInputErrors({ ...inputErrors, [name]: '' });
  };

  const validateAge = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    const age = today.getFullYear() - selectedDate.getFullYear();
    const monthDiff = today.getMonth() - selectedDate.getMonth();
    const isOver18 = age > 18 || (age === 18 && monthDiff >= 0);
    const formattedDate = selectedDate.toISOString().split('T')[0];
    setIsOver18(isOver18);

    handleInputChange({ target: { name: 'dob', value: formattedDate } });
  };

  const handleDateSelect = async (date) => {
    setDateSelectLoading(true);
    setStartDate(date);
    setIstimeSlotErr(false);
    setSelectedTimeSlot(null)

    // Use moment.js to format the date as YYYY-MM-DD in local time
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const formattedDateUk = moment(date).format('DD/MM/YYYY');
    setSelectedDate(formattedDateUk);
    await fetchTimeSlots(formattedDate);
    handleInputChange({ target: { name: 'date', value: formattedDate } });

    if (timeSlotsRef.current) {
      timeSlotsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchTimeSlots = async (date) => {
    try {
      setDateSelectLoading(true);
      const services = cartItems.filter(item => item.serviceType === "service");
      console.log(services)
      const res = await post('api/booking/time/slots', { services, providerId: 2, date});
      console.log(res)
      if (res.success && res.data) {
        setAvailableTimeSlots(res.data);
        setIsDateSelected(true);
      } else {
        setAvailableTimeSlots([]);
        setIsDateSelected(false);
      }
      setDateSelectLoading(false)

    } catch (error) {
      setAvailableTimeSlots([]);
      setIsDateSelected(false);
      setDateSelectLoading(false)

    }
  };

  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
  const convertMinutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:00`;
  };

  const filterTimeSlots = () => {

    if (availableTimeSlots && availableTimeSlots.length > 0) {
      const totalDuration = calculateTotalDuration();
      const slotDuration = 15; // assuming each slot is 15 minutes

      const timeSlotsInMinutes = availableTimeSlots.map(convertTimeToMinutes);
      const requiredSlots = totalDuration / slotDuration;
      const validSlots = [];

      for (let i = 0; i <= timeSlotsInMinutes.length - requiredSlots; i++) {
        let isValid = true;

        for (let j = 0; j < requiredSlots; j++) {
          if (timeSlotsInMinutes[i + j] !== timeSlotsInMinutes[i] + j * slotDuration) {
            isValid = false;
            break;
          }
        }

        if (isValid) {
          validSlots.push(availableTimeSlots[i]);
        }
      }

      const newlyFiltered = validSlots.filter(slot => slot !== undefined);
      setFilteredTimeSlots(newlyFiltered);
    } else {
      setFilteredTimeSlots([]);
    }

  };


  // const handleTimeSlotSelect = (timeSlot) => {

  //   setSelectedTimeSlot(timeSlot);
  //   handleInputChange({
  //     target: {
  //       name: 'time',
  //       value: timeSlot
  //     }
  //   });
  //   setIstimeSlotErr(false)
  // };

  const handleTimeSlotSelect = (timeSlot) => {
    const selectedSlotMinutes = convertTimeToMinutes(timeSlot);
    const totalDuration = calculateTotalDuration();
    const slotDuration = 15; // Assuming each slot is 15 minutes
    const requiredSlots = Math.ceil(totalDuration / slotDuration);

    const validSlots = Array.from({ length: requiredSlots }, (_, i) =>
      convertMinutesToTime(selectedSlotMinutes + i * slotDuration)
    );



    // Check if all valid slots are available
    const allSlotsAvailable = validSlots.every(slot => availableTimeSlots.includes(slot));

    if (allSlotsAvailable) {
      setSelectedTimeSlot(timeSlot);
      setConsistentTimeSlots(validSlots);
      handleInputChange({ target: { name: 'time', value: timeSlot } });
      handleInputChange({ target: { name: 'slots', value: validSlots } });
      setIstimeSlotErr(false);
    } else {
      console.log('Selected slots do not exist in the available list');
      setIstimeSlotErr(true);
    }
  };



  const calculateSubtotal = () => {
    let subtotal = 0;
    if (cartItems.length !== 0) {
      cartItems.forEach((item) => {
        if (item.type === 'Service') {
          subtotal += item.price;
        } else if (item.type === 'Add-Ons') {
          subtotal += item.discountPrice;
        }
      });
    }
    return subtotal;
  };
  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    let bloodDrawFee = 0;
    if (isBloodDraw) {
      bloodDrawFee = 30;
    }
    const total = subtotal + bloodDrawFee;
    return total;
  };

  const validateFormData = () => {
    const errors = {};

    // Check for required fields
    Object.keys(formData).forEach((key) => {
      if (!['date', 'time', 'userToken', 'slots'].includes(key) && !formData[key]) {
        errors[key] = 'This field is required';
      }
    });

    // Additional validations
    if (formData.email && !emailRegex.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (formData.phone && !nineDigitRegex.test(formData.phone)) {
      errors.phone = 'Phone number must be at least 9 digits';
    }

    return errors;
  };
  const validateCheckoutForm = () => {
    const newErrors = validateFormData();
    setInputErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      const firstErrorKey = Object.keys(newErrors)[0];
      firstErrorRef.current[firstErrorKey].focus();
      setIsSubmit(false);
      return false;
    }

    if (!isOver18) {
      setIsSubmit(false);
      return false;
    }

    if (!selectedTimeSlot) {
      setIstimeSlotErr(true);
      if (timeSlotsRef.current) {
        timeSlotsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setIsSubmit(false);
      return false;
    }

    return true;
  };


  const handleSubmit = async (e) => {

    e.preventDefault();
    setIsSubmit(true);

    const newErrors = validateFormData();


    setInputErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      const firstErrorKey = Object.keys(newErrors)[0];
      firstErrorRef.current[firstErrorKey].focus();
      setIsSubmit(false);
      return;
    }

    if (!isOver18) {
      setIsSubmit(false);
      return;
    }

    if (!selectedTimeSlot) {
      setIstimeSlotErr(true);
      if (timeSlotsRef.current) {
        timeSlotsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setIsSubmit(false);
      return;
    }

    try {
      const res = await post('api/booking/create', formData);
      console.log(res);
      if (res.success) {
        setIsSubmit(false);

      } else {

      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error
    } finally {
      setIsSubmit(false);
    }
  };




  const breadcrumbs = [
    { label: 'Home', path: 'https://www.beta.myprivatechemist.com/' },
    { label: 'Checkout' },
  ];


  return (
    <>
      <main id="content" className="wrapper layout-page">
        <div className='breadcrumnb-img-bg'>
          <div className='container'>
            <NewNavbar />
            <BreadCrumbsNew breadcrumbs={breadcrumbs} title={'Checkout'} />
          </div>
        </div>
        <div className="container">
          {isLoading && <PageLoader />}
          {cartItems.length > 0 && (
            <div className="row" style={{ marginTop: '50px', paddingBottom: '50px' }}>
              <div className="col-12">
                <h4 style={{ marginBottom: '40px', marginTop: '40px' }}>Billing Details</h4>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <BillingDetail
                  formData={formData}
                  handleInputChange={handleInputChange}
                  inputErrors={inputErrors}
                  validateAge={validateAge}
                  isOver18={isOver18}
                  firstErrorRef={firstErrorRef}
                />
                <h4 className="" style={{ marginBottom: '40px', marginTop: '40px' }}>Select Booking Date & Time</h4>
                {isTimeSlotErr &&
                  <p className="mb-5" style={{ color: 'red', fontSize: '12px' }}><i className="fa fa-warning"></i> Please choose time for your appointment</p>
                }
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <BookingDatePicker
                      startDate={startDate}
                      handleDateSelect={handleDateSelect}
                      currentDate={new Date()}
                      containerRef={containerRef}
                      isOpen={isOpen}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 timeslot-section" ref={timeSlotsRef}>
                    {dateSelectLoading ? (
                      <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '30vh' }}>
                        <Spinner variant="warning" animation="grow" size="xl" className="mr-2" />
                      </div>
                    ) : (
                      <>
                        <div >
                          <TimeSlotList
                            selectedTimeSlot={selectedTimeSlot}
                            handleTimeSlotSelect={handleTimeSlotSelect}
                            timeSlots={filteredTimeSlots}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                {/* <Elements stripe={stripePromise}> */}
                <OrderSummary
                  handleSubmit={handleSubmit}
                  cartItems={cartItems}
                  calculateSubtotal={calculateSubtotal}
                  calculateTotal={calculateTotal}
                  isSubmit={isSubmit}
                  isBloodDraw={isBloodDraw}
                  filteredTimeSlots={filteredTimeSlots}
                  selectedDate={selectedDate}
                  selectedTimeSlot={selectedTimeSlot}
                  validateCheckoutForm={validateCheckoutForm}
                />
                {/* </Elements> */}
              </div>
            </div>
          )}
                    {cartItems.length == 0 && (
                      <div
                        className="row p-5"
                        style={{ marginTop: "100px", marginBottom: "100px" }}
                      >
                        <div className="col-lg-2 col-12"></div>
                        <div className="col-lg-4 col-12 ">
                          <img
                            src="/assets/images/empty-cart.png"
                            alt=""
                            style={{ width: "250px" }}
                          />
                        </div>
                        <div className="col-lg-4 col-12">
                          <div>
                            <h2 className="mt-lg-0 mt-10 mb-10">Sorry...</h2>
                            <p className="error-desc metropill mb-12">
                              Your cart is currently empty. We invite you to explore our
                              available services to find the perfect option for you.
                            </p>
                            <Link
                              to={"https://www.beta.myprivatechemist.com"}
                              className="btncust btn-pad w-auto d-inline-block text-decoration-none metropill"
                            >
                              Explore
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-2 col-12"></div>
                      </div>
                    )}
        </div>
        <NewFooter />
      </main>
    </>
  );
};

export default Checkout;
