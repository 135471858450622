import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage
import cartReducer from "./slices/CartSlice";
import bmiReducer from "./slices/BmiSlice";
import consultationReducer from "./slices/ConsultationSlice";

// Combine all reducers
const rootReducer = combineReducers({
  cart: cartReducer,
  bmi: bmiReducer,
  consultation: consultationReducer,
});

// Redux Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart","consultation"], // Only persist the cart, exclude BMI if not needed
};

// Persist the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
