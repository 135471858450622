import React, { useEffect, useState } from 'react'
import DrawerComponent from './Card/Drawer'
import { Link } from 'react-router-dom';
import TextWithReadMore from '../../components/TextWithReadMore';
import { get } from '../../services/ApiService.js';
import { Typography } from 'antd';

const { Paragraph } = Typography
const DataDisplay = ({isLoad, isNotFound, data, isItemSelected, biomarkerData,handleAdd, handleRemove, selectedServiceType}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedBiomarker, setSelectedBiomarker] = useState(null);
      const [biomarkers, setBiomarkers] = useState([]);

      useEffect(() => {
        fetchBiomarkers();
        
      }, []);
    
      const fetchBiomarkers = async () => {
        try {
          const res = await get('api/package/biomarkers');
          if (res.success) {
            setBiomarkers(res.biomarkers);
          } else {
            setBiomarkers([]);
          }
    
        } catch (error) {
            setBiomarkers([]);
        }
      };

      const closeDrawer = () => {
        setDrawerOpen(false);
        setSelectedBiomarker(null);
        document.body.style.overflow = 'auto';
      };

      const handleBiomarkerClick = (item) => {
        const biomarkersToShow = item.type === 'bio' ? [item._id] : item.biomarkers;
        const fetchedBiomarkers = biomarkerData.filter(biomarker => biomarkersToShow.includes(biomarker._id));
        setSelectedBiomarker(fetchedBiomarkers);
        setDrawerOpen(true);
        document.body.style.overflow = 'hidden';
    
    };

    function truncateText(text, wordLimit) {
      if (typeof text !== "string" || typeof wordLimit !== "number") {
        return "";
      }
    
      if (text.length > wordLimit) {
          let truncated = text.slice(0, wordLimit);
          const lastSpaceIndex = truncated.lastIndexOf(" "); // Find the last space
          if (lastSpaceIndex !== -1) {
            truncated = truncated.slice(0, lastSpaceIndex); // Remove the incomplete word
          }
          return truncated;
      }
      return text.trim(); // Trims even if it's shorter than the limit
    }
  return (
    <div className='mt-10 mt-lg-0'>
        <div className='row g-lg-10 g-5'>
            {isLoad && 
            <div className='col-md-12 text-center' style={{marginTop:'50px'}}>
                <span className="conslut-loading"></span>
            </div>
            }
            {!isLoad && data && data.length > 0 &&
            data.map((item, index) => (
                <div className='col-lg-4' key={index} >
                    <div className={`card rounded-4 shadow-c position-relative border-0 p-4 h-100 ${item.type === 'bio' ? 'border-main':'border-pro'}`} >
                        <div className='card-header bg-transparent border-0' style={{height: 100}}>
                            <h6 className='text-center fs-21' title={item.type === 'bio' ? item.biomaker : item.name} >
                                {item.type === 'bio' ? item.biomaker : item.name}
                            </h6>
                        </div>
                        <div className='card-body pb-3'>
                            <p className='metrophofont'> 
                              {truncateText(item?.description, 250)}
                            </p>               
                        </div>
                        <div className='card-footer bg-transparent border-0'>
                          <div className='d-flex justify-content-center mb-10'>
                            <Link className="tag-btn3 text-decoration-none text-capitalize gap-3" onClick={() => handleBiomarkerClick(item, selectedServiceType)}> 
                                <span className='fw-semibold'>{item.type === 'bio' ? 1 : item.biomarkers.length}</span>
                                Biomarker Analysed
                            </Link>
                          </div>  
                          <div className='d-flex justify-content-end'>
                            {isItemSelected(item._id) ? (
                            <button type='button' className={`${item.type === 'pro' ? 'btn-green active mt-0':'btn-show active mt-0'}`} onClick={() => handleRemove(item._id)}>
                                Remove
                            </button>
                            ):(
                                <button type='button' className={`${item.type === 'pro' ? 'btn-green mt-0':'btn-show mt-0'}`} onClick={() => handleAdd(item._id, item.type)}>
                                Add
                                </button>
                            )}
                          </div> 
                        </div>
                    </div>
                </div>
            ))
            }
            {isNotFound && 
            <div className='col-12 text-center'>
                <h4 className='fw-normal'>OOPS!</h4>
                <p className='fw-normal '>No results found. Alternatively, kindly check the spelling. </p>
            </div>
            }
        </div>

        <DrawerComponent
          isOpen={drawerOpen}
          onClose={closeDrawer}
          biomarkerData={selectedBiomarker}
        />
    </div>
  )
}

export default DataDisplay