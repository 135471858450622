import React from 'react'
import { NavLink } from 'react-router-dom'
import './index.css'

const NewFooter = () => {

    const data = [
        {
            title:'Contact',
            id: 1,
            content:[
                {
                    icon:"my-pin.jpg",
                    desc:"Longcroft House,<br> 2-8 victoria Avenue<br> London, EC2M 4NS",
                    isHTML: true
                },
                {
                    icon:"my-mail.jpg",
                    desc:'<a href="mailto:info@myprivatechemist.com">info@myprivatechemist.com</a>',
                    isHTML: true
                },
                {
                    icon:"my-phone.jpg",
                    desc:'<a href="tel:0203 987 920">0203 987 920</a>',
                    isHTML: true
                },
            ]
        },
        {
            title:'Quick Links',
            id:2,
            content:[
                // {
                //     path:'',
                //     desc:"Home"
                // },
                // {
                //     path:'',
                //     desc:"Our Mission"
                // },
                {
                    path:'',
                    desc:"Blood Testing"
                },
                {
                    path:'',
                    desc:"Build My Package"
                },
                {
                    path:'',
                    desc:"A-Z Test"
                },
                {
                    path:'',
                    desc:"Contact Us"
                },
                {
                    path:'/weight-loss/',
                    desc:"Weight Loss"
                },
                // {
                //     path:'',
                //     desc:"Travel Clinic"
                // },
                // {
                //     path:'',
                //     desc:"Treatment & Medication"
                // },
                // {
                //     path:'',
                //     desc:"Consultation"
                // },
                // {
                //     path:'',
                //     desc:"GP Services"
                // },
                // {
                //     path:'',
                //     desc:"Health Checks"
                // },
                // {
                //     path:'',
                //     desc:"Corporate"
                // },
                // {
                //     path:'',
                //     desc:"Sexual Health"
                // },
            ]
        },
        {
            title:'Opening',
            id:3,
            content:[
                {
                    desc:"Monday 09:00-1800"
                },
                {
                    desc:"Tuesday 09:00-1800"
                },
                {
                    desc:"Wednesday 09:00-1800"
                },
                {
                    desc:"Thursday 09:00-1800"
                },
                {
                    desc:"Friday 09:00-1800"
                },
                {
                    desc:"Saturday Closed"
                },
                {
                    desc:"Sunday Closed"
                },
            ]
        },
    ]

    const socialdata = [
        {
            title:'Follow Us or Contact',
            content:[
                {
                    path:'',
                    img:'my-fb.jpg'
                },
                {
                    path:'',
                    img:'my-tw.jpg'
                },
                {
                    path:'',
                    img:'my-insta.jpg'
                },
            ]
        },
        {
            title:'Payment Accepted',
            id: 2,
            content:[
                {
                    path:'',
                    img:'my-visa.jpg'
                },
                {
                    path:'',
                    img:'my-card.jpg'
                },
            ]
        },

    ]
  return (
    <div className='footer-new'>
        <div className='container'>
            <div className='row g-3'>
                {
                    data?.map((data, index)=>
                        <div className={data?.id === 2 ? 'col-xl-6 col-lg-4' : 'col-xl-3 col-lg-4'} key={index}>
                            <div className={
                                data?.id === 2 ? 'mar-list' : null
                            }>
                                <h4 className='fw-bold title metropill footer-title'>{data?.title}</h4>
                                {
                                data.id === 1 ? (
                                    data?.content?.map((cont, i) => (
                                    <div className="d-flex gap-3 align-items-start mb-3" key={i}>
                                        <div className="flex-shrink-0 d-flex pt-3">
                                        <img
                                            src={`/assets/icons/${cont?.icon}`}
                                            width={16}
                                            alt=""
                                            className="img-fluid img-mails"
                                        />
                                        </div>
                                        <p className="m-0 metropill desc desc-new" dangerouslySetInnerHTML={{ __html: cont?.desc }}></p>
                                    </div>
                                    ))
                                ) 
                                : 
                                data.id === 2 ? (
                                    <div className="list">
                                        <ul>
                                            {data?.content?.map((cont, i) => (
                                            <li key={i}>
                                                <NavLink to={''}>
                                                    <div className="d-flex gap-10p align-items-start mb-3">
                                                        <div className="flex-shrink-0 d-flex pt-3">
                                                        <img
                                                            src="/assets/icons/my-plus.png"
                                                            width={16}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        </div>
                                                        <p className="m-0 metropill desc link-new">{cont?.desc}</p>
                                                    </div>
                                                </NavLink>
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <div>
                                        {data?.content?.map((cont, i) => (
                                            <p className="m-0 metropill desc desc-new">{cont?.desc}</p>
                                        ))}
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    )
                }
                
            </div>
            <div className='row mt-5 pt-5'>
                <div className='col-lg-4 col-xl-3'>
                    <div className='reg-area'>
                        <p className='metropill fs-13 mb-3'>
                            <strong className='metropill fw-bold'>Company: </strong>
                            MI Health Ltd
                        </p>
                        <p className='metropill fs-13 mb-3'>
                            <strong className='metropill fw-bold'>Premisess GPhC Number: </strong>
                            9011548
                        </p>
                        <p className='metropill fs-13 mb-3'>
                            <strong className='metropill fw-bold'>Superintendent: </strong>
                            Imran Tailor (221614)
                        </p>
                        <img src="/assets/icons/gen.png" width={146} className='img-fluid' alt="" />
                    </div>
                </div>
                <div className='col-lg-4 col-xl-6 my-auto'>
                    <div className='d-flex justify-content-center my-5'>
                        <NavLink to={''}>
                            <img src="/logo-footer.png" className='img-fluid logo-footer' width={180} height={87} alt="" />
                        </NavLink>
                    </div>
                </div>
                <div className='col-lg-4 col-xl-3'>
                    {
                        socialdata?.map((social,i)=>
                        <div className='mb-5 social-icons' key={i}>
                            <h4 className='fw-bold mt-4 mb-5 title metropill'>
                                {social?.title}
                            </h4>
                            <div className='d-flex align-items-center gap-3 justify-content-end py-4'>
                                {
                                    social?.content?.map((listsocial,a)=>
                                        <NavLink to={listsocial?.path} key={a}>
                                            <img src={'/assets/icons/'+listsocial?.img} width={social?.id === 2 ? 33 : 18} />
                                        </NavLink>
                                    )
                                }
                            </div>
                        </div>
                        )
                    }

                </div>
            </div>
            <div className="row">
                <div className='col-lg-12'>
                    <div className='text-center'>
                        <p className='m-0 metropill copy-right-para'>
                            Copyright © by MPC 2025. All rights reserved.
                        </p>
                    </div>
                </div>
                </div>
        </div>
    </div>
  )
}

export {NewFooter}