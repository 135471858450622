import React, { useState } from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'

const Sidebar = ({selectedItems, buildPkgRecomendation, isLoading, isRecommending, setIsRecommending, handleRemove}) => {

  return (
    <div className='bg-filter rounded-4'>
        <div className='p-7'>
            <div>
                <h5 className='mb-7'>Selected Items</h5>
                <div className='d-flex align-items-center pt-2 flex-wrap mt-5 gap-6s mb-5 side-collapse' >
                    {selectedItems.length > 0 ? (
                        selectedItems.map((item, index) => (
                        <div class={`${item.type === 'bio'? 'tag-btn':'tag-btn-green'} text-decoration-none gap-3 text-capitalize`} key={index}> 
                            {item?.name}
                            <Link onClick={() => handleRemove(item.id)} className='fs-20 text-decoration-none'>×</Link>
                        </div>
                        ))
                    ):(
                        <p className='fs-14 text-center w-100 metropill'>No item selected yet!</p>
                    )}
 
                </div>
            </div>
            

            {selectedItems.length > 0 &&
                <>
                {isRecommending ? (
                    <button type='button' className='btncust' onClick={()=>{setIsRecommending(false)}} >
                         Edit My Package
                    </button>
                ):(
                    <button type='button' className='btncust' onClick={buildPkgRecomendation} disabled={isLoading}>
                        {isLoading && <LoadingOutlined className='colorload' />}   Build My Package
                    </button>
                )}

                </>
            }


        </div>        
    </div>
  )
}

export {Sidebar}