import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { HeaderForm } from "./HeaderForm";
import { post, get } from "../../services/ApiService.js";
import { Bmi } from "./BmiStep/Bmi";
import { FormSkeleton } from "./Loader/index.jsx";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import uploadIconFullBody from "../../assets/images/full-body-example.jpg";
import { setConsultationData } from "../../redux/slices/ConsultationSlice.js";

import {
  CloseOutlined,
  InboxOutlined,
  CameraOutlined,
} from "@ant-design/icons";

import RenderFileInput from "./RenderFileInput.jsx";

const Consultation = () => {

  const dispatch = useDispatch();
  const bmiState = useSelector((state) => state.bmi);
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(true);
  const [isSumbit, setIsSumbit] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [formData, setForm] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [answers, setAnswers] = useState({});
  const [explanations, setExplanations] = useState({});
  const [errors, setErrors] = useState({});
  const [questions, setQuestions] = useState([]);
  const [dependantQuestions, setDependentQuestions] = useState({});
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [questionScores, setQuestionScores] = useState({});
  const [isBmi, setIsBmi] = useState(false);
  const [currentWeight, setCurrentWeight] = useState(0);
  const [currentBmiCategory, setCurrentBmiCategory] = useState(null);


  useEffect(() => {
    fetchData();
  }, [slug]);

  useEffect(() => {
    // Recalculate scores whenever answers or questions change
    questions.forEach((question) => {
      updateScoreForQuestion(question._id);
    });
    // dependantQuestions.forEach(question => {
    //   updateScoreForQuestion(question._id);
    // });
  }, [answers, questions]);



  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await get(`api/assessment/form/${slug}`);
      setIsLoading(false);
      if (res.success) {
        let assessmentForm = res.form;
        setForm(assessmentForm);
        setQuestions(assessmentForm.questions);
        setDependentQuestions(res.depQuestions);
        if (assessmentForm.requiresBMI && assessmentForm.requiresBMI === true) {
          if (bmiState.bmiValue && bmiState.bmiValue > 0) {
            setIsBmi(false);
          } else {
            setIsBmi(true);
          }
        }
      } else {
        setForm("");
        setQuestions({});
        setDependentQuestions({});
        setNotFound(true);
      }
    } catch (error) {
      setIsLoading(false);
      setNotFound(true);
      console.error(error.message);
    }
  };

  const calculateScore = (question) => {
    if (!question || !question.isScoreable) return 0;

    const selectedOptions = answers[question._id] || [];

    let score = 0;
    selectedOptions.forEach((optionId) => {
      const optionIndex = question.options.findIndex(
        (opt) => opt._id === optionId
      );
      if (optionIndex !== -1) {
        score += optionIndex + 1;
      }
    });

    return score;
  };

  // Update the score for a question
  const updateScoreForQuestion = (questionId) => {
    const question =
      questions.find((q) => q._id === questionId) ||
      dependantQuestions.find((q) => q._id === questionId);
    if (question) {
      const score = calculateScore(question);
      setQuestionScores((prevScores) => ({
        ...prevScores,
        [questionId]: score,
      }));
    }
  };

  const handleStepChange = (direction) => {
    if (direction === "next") {
      // Validate current step before advancing
      if (!validateStep()) return;

      // Proceed to the next step
      setFadeClass("fade-out");
      setTimeout(() => {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, questions.length)); // Ensure currentStep doesn't exceed total questions
        setFadeClass("fade-in");
      }, 500);
    } else if (direction === "back") {
      // Go back to the previous step
      if (currentStep === 1 && formData.requiresBMI) {
        setFadeClass("fade-out");
        setTimeout(() => {
          setIsBmi(true);
          setFadeClass("fade-in");
        }, 500);
      } else {
        setFadeClass("fade-out");
        setTimeout(() => {
          setCurrentStep((prevStep) => Math.max(prevStep - 1, 1)); // Ensure currentStep doesn't go below 1
          setFadeClass("fade-in");
        }, 500);
      }
    }
  };

  const handleBMIValueChange = (bmiValue, heightCm, weightKg, category) => {
    setCurrentWeight(weightKg);
    setCurrentBmiCategory(category);
  };

  const immediateValidation = (question, updatedAnswers) => {
    if (!question || !question.options) return true;

    const selectedOptions = updatedAnswers[question._id] || [];
    if (selectedOptions.length === 0 && question?.isRequired) {
      setErrors((prev) => ({
        ...prev,
        [question._id]: "This question is required",
      }));
      return false;
    }

    const selectedOption = question.options.find((opt) =>
      selectedOptions.includes(opt._id)
    );
    const wrongSelectedOption = question.options.find(
      (opt) =>
        selectedOptions.includes(opt._id) && opt.isCorrectAnswer === false
    );
    const hasCorrectAnswer = question.options.some(
      (opt) => opt.isCorrectAnswer
    );
    if (selectedOption && hasCorrectAnswer && wrongSelectedOption) {
      // setErrors(prev => ({ ...prev, [question._id]: 'Your answer is wrong' }));
      setErrors((prev) => ({
        ...prev,
        [question._id]: "Based on your answer, we are unable to proceed.",
      }));
      return false;
    }

    // if (selectedOption?.requireExplanation && !explanations[question._id]) {
    //   setErrors(prev => ({ ...prev, [question._id]: 'Explanation is required' }));
    //   return false;
    // }

    setErrors((prev) => ({ ...prev, [question._id]: "" }));
    return true;
  };

  const validateAnswer = (question) => {
    if (!question) return true;

    const selectedOptions = answers[question._id] || [];

    if (question.isRequired && selectedOptions.length === 0) {
      setErrors((prev) => ({
        ...prev,
        [question._id]: "This question is required",
      }));
      return false;
    }
    console.log(selectedOptions);
    const selectedOption = question.options.find((opt) =>
      selectedOptions.includes(opt._id)
    );
    const isRequireExp = question.options.find(
      (opt) =>
        selectedOptions.includes(opt._id) && opt.requireExplanation === true
    );
    if (isRequireExp && !explanations[question._id]) {
      setErrors((prev) => ({
        ...prev,
        [question._id]:
          "Please provide an explanation to this question to continue to the next step",
      }));
      return false;
    }
    // Validate dependent questions
    const dependentOptions = question.options.filter(
      (opt) => opt.dependant && selectedOptions.includes(opt._id)
    );
    for (const opt of dependentOptions) {
      const depQuestion = dependantQuestions.find(
        (q) => q._id === opt.questionId
      );
      if (depQuestion && depQuestion.isRequired && !answers[opt.questionId]) {
        setErrors((prev) => ({
          ...prev,
          [opt.questionId]: "This dependent question is required",
        }));
        return false;
      }
      if (errors[opt.questionId]) return false;
    }

    setErrors((prev) => ({ ...prev, [question._id]: "" }));
    return true;
  };

  const validateStep = () => {
    let question;
    question = questions.find((q) => q.step === currentStep);
    if (!question) {
      question = dependantQuestions.find((q) => q.step === currentStep);
    }

    // Validate current question
    if (!validateAnswer(question)) return false;

    const questionScore = calculateScore(question);

    // Validate dependent questions
    const dependentQuestionId = dependantQuestions[question._id];
    if (dependentQuestionId) {
      const dependentQuestion = dependantQuestions.find(
        (q) => q._id === dependentQuestionId
      );
      if (dependentQuestion && !answers[dependentQuestionId]) {
        setErrors((prev) => ({
          ...prev,
          [dependentQuestionId]: "This question is required",
        }));
        return false;
      }
    }

    return true;
  };

  const handleAnswerChange = (questionId, _id) => {
    setAnswers((prev) => {
      let question =
        questions.find((q) => q._id === questionId) ||
        dependantQuestions.find((q) => q._id === questionId);

      let selectedOptions = prev[questionId] || [];
      let updatedAnswers;

      // Handle radio input
      if (question.inputType === "radio") {
        updatedAnswers = { ...prev, [questionId]: [_id] };
      }
      // Handle checkbox input
      if (question.inputType === "checkbox") {
        const isSelected = selectedOptions.includes(_id);
        updatedAnswers = {
          ...prev,
          [questionId]: isSelected
            ? selectedOptions.filter((opt) => opt !== _id)
            : [...selectedOptions, _id],
        };
        selectedOptions = updatedAnswers[questionId];

        // Handle exclusive option logic
        if (question.options.some((opt) => opt.exclusive)) {
          const exclusiveOption = question.options.find((opt) => opt.exclusive);
          if (exclusiveOption) {
            if (exclusiveOption._id !== _id) {
              updatedAnswers[questionId] = updatedAnswers[questionId].filter(
                (opt) => opt !== exclusiveOption._id
              );
            } else if (_id === exclusiveOption._id) {
              updatedAnswers = { ...prev, [questionId]: [exclusiveOption._id] };
            }
          }
        }
      }

      if (question.inputType === "file") {
        console.log(_id);
        updatedAnswers = { ...prev, [questionId]: _id };
      }

      if (
        question.inputType === "radio" ||
        question.inputType === "select" ||
        question.inputType === "checkbox"
      ) {
        // Perform immediate validation for the main question
        immediateValidation(question, updatedAnswers);

        // Validate dependent questions
        const selectedOption = question.options.find((opt) => opt._id === _id);
        if (selectedOption?.dependant) {
          const depQuestion = dependantQuestions.find(
            (q) => q._id === selectedOption.questionId
          );

          // If the option is deselected, clear the dependent question's answers and errors
          if (!updatedAnswers[questionId].includes(_id)) {
            updatedAnswers[selectedOption.questionId] = [];
            setErrors((prev) => ({
              ...prev,
              [selectedOption.questionId]:
                "This dependent question is required",
            }));
          }

          // Validate the dependent question
          if (depQuestion) {
            const isDepValid = validateAnswer(depQuestion);
            if (!isDepValid) {
              setErrors((prev) => ({
                ...prev,
                [selectedOption.questionId]:
                  "This dependent question is required",
              }));
            }
          }
        }
      }
      return updatedAnswers;
    });
  };

  const handleExplanationChange = (questionId, explanation) => {
    setExplanations((prev) => ({ ...prev, [questionId]: explanation }));

    const question = questions.find((q) => q._id === questionId);
    if (
      question?.options.some(
        (opt) => opt._id === answers[questionId]?.[0] && opt.requireExplanation
      ) &&
      !explanation
    ) {
      setErrors((prev) => ({
        ...prev,
        [questionId]:
          "Please provide an explanation to this question to continue to the next step",
      }));
    } else {
      setErrors((prev) => ({ ...prev, [questionId]: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSumbit(true);

    const consultationData = {
      answers,
      explanations,
      formId: formData._id,
    };

    // Dispatch the action to set consultation data in Redux
    dispatch(setConsultationData(consultationData));
    setIsSumbit(false);
    navigate("/products");
  };

  const renderOptions = (question) => {
    return question.options.map((opt, index) => {
      const isActive =
        question.inputType === "radio"
          ? answers[question._id]?.[0] === opt._id
          : answers[question._id]?.includes(opt._id) || false;

      return (
        <div className="col-lg-12 " key={index}>
          <input
            type={question.inputType}
            id={`option-${index}-${question._id}`}
            name={`question-${question._id}`}
            value={opt._id}
            className="d-none"
            onBlur={() => validateAnswer(question)}
          />
          <label
            className={
              isActive
                ? "padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3"
                : "padc bullet w-100 d-flex align-items-center gap-3 rounded-3"
            }
            onClick={() => handleAnswerChange(question._id, opt._id)}
            htmlFor={`option-${index}-${question._id}`}
          >
            <div
              className={`${
                question.inputType === "radio"
                  ? "label-cs"
                  : "label-check flex-shrink-0"
              }`}
            ></div>
            <span className="metropill">{opt.optionText}</span>
          </label>
        </div>
      );
    });
  };

  const renderStep = () => {
    const question = questions.find((q) => q.step === currentStep);
    if (!question) return null;

    const selectedOptions = answers[question._id] || [];
    const selectedOption = question.options.find((opt) =>
      selectedOptions.includes(opt._id)
    );
    const isRequireExp = question.options.find(
      (opt) =>
        selectedOptions.includes(opt._id) && opt.requireExplanation === true
    );

    return (
      <div className="row g-5">
        <div className="col-lg-12">
          <h5 className="mb-5">{question.title}</h5>
        </div>
        {(question.inputType === "radio" ||
          question.inputType === "checkbox") &&
          renderOptions(question)}
        {question.inputType === "text" && (
          <div className="col-lg-12">
            <input
              type="text"
              onChange={(e) => handleAnswerChange(question._id, e.target.value)}
              onBlur={() => validateAnswer(question)}
              value={selectedOptions[0] || ""}
            />
          </div>
        )}
        {question.inputType === "file" && 
          <RenderFileInput question={question} answers={answers} handleAnswerChange={handleAnswerChange} />
        }
        {/* Conditionally render explanation input */}
        {isRequireExp && (
          <div
            className={`col-lg-12 ${
              isRequireExp ? "animatecs active fade-inn" : "animatecs fade-outt"
            }`}
            style={{ marginTop: "40px" }}
          >
            <label
              htmlFor={`explanation-${question._id}`}
              className="text-dark"
            >
              Specify More <span className="text-danger">*</span>
            </label>
            <textarea
              id={`explanation-${question._id}`}
              className="form-control metropill quiz-text"
              rows={5}
              value={explanations[question._id] || ""}
              onChange={(e) =>
                handleExplanationChange(question._id, e.target.value)
              }
              onBlur={() => validateAnswer(question)}
            />
          </div>
        )}
        {errors[question._id] && (
          <div className="col-lg-12">
            <div
              className={
                errors[question._id]
                  ? "d-flex align-items-center mt-3 animatecs active fade-inn"
                  : "d-flex align-items-center mt-3 animatecs fade-outt"
              }
            >
              <img src="/assets/images/error.png" width={16} alt="Error" />
              <p className="text-danger fs-12 m-0 ms-2 pt-2">
                {errors[question._id]}
              </p>
            </div>
          </div>
        )}
        {renderDependentQuestions()} {/* Render dependent questions */}
      </div>
    );
  };

  const renderDependentQuestions = () => {
    const currentQuestion = questions.find((q) => q.step === currentStep);
    if (!currentQuestion) return null;

    // Find the options that are selected and require dependent questions
    const selectedOptions = answers[currentQuestion._id] || [];
    const dependentOptions = currentQuestion.options.filter(
      (opt) => opt.dependant && selectedOptions.includes(opt._id)
    );

    if (!dependentOptions.length) return null;

    return dependentOptions.map((opt, index) => {
      const depQuestion = dependantQuestions.find(
        (q) => q._id === opt.questionId
      );

      if (!depQuestion) return null;

      const isActive = answers[depQuestion._id] || [];
      const options = depQuestion.options.map((opt, idx) => (
        <div className="col-lg-12 mb-4" key={idx}>
          <input
            type={depQuestion.inputType}
            id={`dep-option-${idx}-${depQuestion._id}`}
            name={`dep-question-${depQuestion._id}`}
            value={opt._id}
            className="d-none"
            onBlur={() => validateAnswer(depQuestion)}
          />
          <label
            htmlFor={`dep-option-${idx}-${depQuestion._id}`}
            className={
              isActive.includes(opt._id)
                ? "padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3"
                : "padc bullet w-100 d-flex align-items-center gap-3 rounded-3"
            }
            onClick={() => handleAnswerChange(depQuestion._id, opt._id)}
          >
            <div
              className={`${
                depQuestion.inputType === "radio" ? "label-cs" : "label-check"
              }`}
            ></div>
            <span className="metropill">{opt.optionText}</span>
          </label>
        </div>
      ));

      return (
        <div key={depQuestion._id} style={{ marginTop: "40px" }}>
          <div className="col-lg-12">
            <h5 className="mb-5">{depQuestion.title}</h5>
            {options}
            {errors[depQuestion._id] && (
              <div className="col-lg-12">
                <div className="d-flex align-items-center mt-3">
                  <img src="/assets/images/error.png" width={16} alt="Error" />
                  <p className="text-danger fs-12 m-0 ms-2 pt-2">
                    {errors[depQuestion._id]}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  const isNextDisabled = () => {
    const question = questions.find((q) => q.step === currentStep);
    if (!question) return true; // Disable if no question exists

    // Disable if required question is not answered
    if (question.isRequired && !answers[question._id]) return true;

    // Disable if there is any error
    if (errors[question._id]) return true;

    // Check if explanation is required but not provided
    const selectedOption = question.options.find(
      (opt) => opt._id === answers[question._id]?.[0]
    );
    if (selectedOption?.requireExplanation && !explanations[question._id])
      return true;

    // Check if a dependent question exists but is not answered
    const dependentOption = question.options.find((opt) => opt.dependant);
    if (dependentOption) {
      const dependentQuestionId = dependentOption.questionId;
      const dependentQuestion = dependantQuestions.find(
        (q) => q._id === dependentQuestionId
      );

      // If the dependent question exists and is required but not answered, disable the Next button
      if (
        dependentQuestion &&
        dependentQuestion.isRequired &&
        !answers[dependentQuestionId]
      )
        return true;

      // Check for errors in dependent questions
      if (errors[dependentQuestionId]) return true;
    }

    return false;
  };

  return (
    <div className="min-height">
      <HeaderForm />
      <div className="mt-5 container">
        <div className="justify-content-md-center row g-3">
          {loading && (
            <div className="col-md-12">
              <form className={`w-form ${fadeClass}`}>
                <div className="d-flex gap-5 flex-column mt-30">
                  <FormSkeleton width="60%" height="15px" />
                  <FormSkeleton width="100%" height="30px" />
                  <FormSkeleton width="100%" height="30px" />
                  <FormSkeleton width="100%" height="30px" />
                  <div className="d-flex align-items-center justify-content-between">
                    <FormSkeleton
                      width="40px"
                      height="40px"
                      borderRadius="50%"
                    />
                    <FormSkeleton
                      width="120px"
                      height="50px"
                      marginLeft="auto"
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
          {!loading && questions.length > 0 && (
            <>
              {isBmi ? (
                <Bmi
                  setIsBmi={setIsBmi}
                  onBmiValueChange={handleBMIValueChange}
                  fadeClass={fadeClass}
                  setFadeClass={setFadeClass}
                  redirectTo={"none"}
                />
              ) : (
                <>
                  <div className="col-md-12">
                    <form className={`w-form ${fadeClass}`}>
                      {renderStep()}
                      <div className="mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-between gap-2">
                        {(currentStep > 1 ||
                          (formData.requiresBMI && currentStep === 1)) && (
                          <button
                            className="border-0 bg-transparent"
                            type="button"
                            onClick={() => handleStepChange("back")}
                          >
                            <img
                              src="/assets/images/backicon.jpg"
                              width={35}
                              alt="Back"
                            />
                          </button>
                        )}

                        {currentStep < questions.length && (
                          <button
                            className={
                              isNextDisabled()
                                ? "btn-disable w-auto activebg"
                                : "btncust w-auto activebg"
                            }
                            type="button"
                            onClick={() => handleStepChange("next")}
                            disabled={isNextDisabled()}
                            style={
                              isNextDisabled() ? { cursor: "not-allowed" } : {}
                            }
                          >
                            Next
                          </button>
                        )}

                        {currentStep === questions.length && (
                          <button
                            className={
                              isNextDisabled()
                                ? "btn-disable w-auto activebg"
                                : "btncust w-auto activebg"
                            }
                            type="submit"
                            onClick={(e) => handleSubmit(e)}
                            disabled={isNextDisabled() || isSumbit}
                          >
                            {isSumbit ? "Please wait..." : "Submit"}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Consultation;
