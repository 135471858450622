import React from 'react'

const HeaderForm = () => {
  return (
    // <div className='py-4 px-4 mb-5 text-center'>
    <div className='py-7 px-4 my-10 text-center'>
        <img src='/logo.png' width={150} />
    </div>
  )
}

export {HeaderForm}