import { createSlice } from "@reduxjs/toolkit";

// Initial state with answers, explanations, and formId
const initialState = {
  answers: {},        // Stores the answers to the consultation questions
  explanations: {},   // Stores the explanations for each answer (if needed)
  formId: null,       // Stores the formId of the current consultation form
};

const consultationSlice = createSlice({
  name: "consultation",
  initialState,
  reducers: {
    // Set the consultation answers, explanations, and formId
    setConsultationData: (state, action) => {
      const { answers, explanations, formId } = action.payload;
      state.answers = answers;
      state.explanations = explanations;
      state.formId = formId;
    },
    
    // Reset the consultation data (clear all fields)
    resetConsultation: (state) => {
      state.answers = {};
      state.explanations = {};
      state.formId = null;
    },
  },
});

// Export actions for use in dispatching
export const { setConsultationData, resetConsultation } = consultationSlice.actions;

// Export the reducer
export default consultationSlice.reducer;
