import React, { useEffect, useState } from "react";
import { NewNavbar, BreadCrumbsNew, NewFooter } from "../../components";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "./index.css";
import weightlossbanner from "../../assets/images/weight-loss.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quoteIcon from "../../assets/images/quote-icon.jpg";
import Maunjaro from "../../assets/images/product-card-maunjaro.webp";
import Wegovy from "../../assets/images/product-card-wegovy.webp";
import { BloodTestContactForm } from "../BloodTesting/BloodTestcomponents/BloodTestContactForm";
import WeightLossCalculator from "../../components/WeightLossCalculator/WeightLossCalculator";
import { Bmi } from "../consultation/BmiStep/Bmi.jsx";
import WeightLossGraph from "../../components/WeightLossGraph/WeightLossGraph";
import { Link } from "react-router-dom";
import WeightLossImg from '../../assets/images/weight-loss-man.jpg';
import phoneIcon from '../../assets/images/phone-icon.jpg';

const testimonials = [
  {
    quote:
      "The weight loss program has completely transformed my life. It's not just about losing weight; it's about gaining confidence and health.",
    author: "James",
    location: "London",
  },
  {
    quote:
      "In my journey to better health, this program has been instrumental. The support and guidance have been exceptional.",
    author: "Elizabeth",
    location: "Manchester",
  },
  {
    quote:
      "The personalized approach made all the difference. I've tried other programs before, but this one actually worked for me.",
    author: "Michael",
    location: "Birmingham",
  },
];

const faqData = [
  {
    question: "What Is The Difference In A Biomarker, Profile And Package?",
    answer:
      "A biomarker is a single test that measures one specific aspect of your health. A profile is a collection of related biomarkers that give you a broader picture of a specific health area. A package combines multiple profiles and biomarkers for comprehensive health screening.",
  },
  {
    question: "How Much Does It Cost?",
    answer:
      "Our weight loss programs are customized to individual needs. The cost varies depending on the duration and intensity of the program. We offer flexible payment plans and packages starting from £X. Contact us for a detailed pricing structure.",
  },
  {
    question: "What Happens During The Appointment And How Long Will It Take?",
    answer:
      "Initial appointments typically last 60-90 minutes. During this time, we'll discuss your health history, goals, and create a personalized weight loss plan. Follow-up appointments are usually 30-45 minutes.",
  },
  {
    question: "Do I Need To Fast Before Starting The Program?",
    answer:
      "Fasting requirements depend on your specific program and any initial tests needed. We'll provide detailed preparation instructions before your first appointment.",
  },
  {
    question: "What Is The Minimum Age To Start The Program?",
    answer:
      "Our weight loss programs are suitable for individuals aged 18 and above. Special programs are available for younger patients with parental consent.",
  },
];

const howItWorksSteps = [
  {
    number: "01",
    title: "Choose your test",
    description:
      "Select your blood test either as specific tests or explore through health concerns. Schedule your convenient time for the clinic visit. Get your detailed results and expert analysis",
  },
  {
    number: "02",
    title: "Book clinic visit",
    description: "Schedule your convenient time for the clinic visit. Get your detailed results and expert analysis. Select your blood test either as specific tests or explore through health concerns.",
  },
  {
    number: "03",
    title: "Receive your results",
    description: "Get your detailed results and expert analysis. Select your blood test either as specific tests or explore through health concerns. Schedule your convenient time for the clinic visit.",
  },
];

const statsData = [
  {
    number: "100",
    unit: "%",
    title: "handcrafted",
    description: "Handmade objects made in small series and more unique",
  },
  {
    number: "20",
    unit: "",
    title: "years of experience",
    description: "Experience collected from the potters and art workshops",
  },
  {
    number: "1800",
    unit: "+",
    title: "unique objects",
    description: "Creating custom designed masterpieces at your demand",
  },
  {
    number: "200",
    unit: "+",
    title: "exhibitions",
    description:
      "Our pottery is exhibited at galleries and private collections",
  },
];

const WeightLoss = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTab, setActiveTab] = useState("step1");
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [isBmi, setIsBmi] = useState(true);
  const [currentWeight, setCurrentWeight] = useState(0);
  const [currentBmiCategory, setCurrentBmiCategory] = useState(null);
  const [isPricingVisible, setIsPricingVisible] = useState(false);

  const handleBMIValueChange = (bmiValue, heightCm, weightKg, category) => {
    setCurrentWeight(weightKg);
    setCurrentBmiCategory(category);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="weightloss-wraper">
      <div className="breadcrumnb-img-bg-weightloss pb-0">
        <div className="container">
          <NewNavbar />
          {/* <BreadCrumbsNew 
                        breadcrumbs={[
                            { label: 'Home', path: '/' },
                            { label: 'Weight Loss', path: '' }
                        ]} 
                        title="Weight Loss" 
                    /> */}

          <div className="weight-loss-page pb-0">
            
              <div className="row">
                <div className="col-lg-6 col-md-7 my-auto">
                  <div className="banner-content">
                    <h1>
                      Weight <span>Loss</span>
                    </h1>
                    {/* <h4>Personalized Care at Your Fingertips <span>(GP Services)</span></h4> */}
                    <p className="mb-10">
                      From blood tests to weight loss programs, we provide
                      expert-led healthcare solutions
                    </p>
                    <Link to={`/consultation/weight-loss`} className="btn btn-primary">
                      Start Consultation
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-5 text-end">
                  <div className="img-banner-wrap">
                    <img src={weightlossbanner} alt="" />
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      </div>
      {/* intro sectoin */}

      
      <section className="intro-section">
        <div className="container">
            <div className="row">
                <div className="col-12">
          <div className="intro-content text-center">
            <h2>Know Your Health With A Simple Blood Test</h2>
            <p className="intro-description">
              Our advanced blood testing services provide accurate insights for
              early detection and personalised care.
            </p>
          </div>
        </div>
                </div>
            </div>


        <div className="intro-sec-inner">
          <div className="container">
            <div className="intro-banner">
              <div className="shadow-intro"></div>
              <div className="row h-100">
                <div className="col-lg-6 my-auto">
                  <div className="banner-content">
                    <h2><span>We make things with love.</span><span>House plants and pots</span></h2>
                    <Link to={`/consultation/weight-loss`} className="btn btn-primary btn-appointment mb-0">
                      Start Consultation
                    </Link>
                  </div>
                </div>
              </div>
            </div>
        <div className="pricing-accordion">
          <button 
            className="accordion-header"
            onClick={() => setIsPricingVisible(!isPricingVisible)}
          >
            {isPricingVisible ? 'Hide' : 'Show'} Pricing
            <span className={`accordion-arrow ${isPricingVisible ? 'open' : ''}`}>▼</span>
          </button>
          
          {isPricingVisible && (
            <div className="pricing-content">
              <div className="pricing-table">
                <div className="pricing-row header">
                  <div className="service-col">Services</div>
                  <div className="price-col">Price</div>
                </div>
                
                <div className="pricing-row">
                  <div className="service-col">Travel Vaccine</div>
                  <div className="price-col">Price</div>
                </div>
                
                <div className="pricing-note">
                  Vaccinations and medications are at an additional fee.
                </div>
                
                <div className="pricing-row">
                  <div className="service-col">Travel Consultation</div>
                  <div className="price-col">£25</div>
                </div>
                
                <div className="pricing-row">
                  <div className="service-col">Travel Health</div>
                  <div className="price-col">£23</div>
                </div>
                
                <div className="pricing-row">
                  <div className="service-col">Asthma</div>
                  <div className="price-col">Price Not added</div>
                </div>
                
                <div className="pricing-row">
                  <div className="service-col">Skin</div>
                  <div className="price-col">Price Not added</div>
                </div>
              </div>
            </div>
          )}
        </div>
          </div>
        </div>
      </section>
      {/* stats Banner */}

      
      {/* pen products section */}

      <section className="weight-loss-products">
        <div className="container">
          <div className="row justify-content-center flex-nowraps">
            <div className="col-xxl-4 col-lg-5 col-md-6">
              <div className="product-card">
                <div className="product-image">
                  <img src={Maunjaro} alt="Mounjaro Pen" />
                </div>
                <div className="product-content">
                  <h3>Mounjaro®</h3>
                  <div className="product-tags">
                    <span className="tag">GIP-1/GIP</span>
                    <span className="tag">Tirzepatide</span>
                  </div>
                  <ul className="product-features">
                    <li>Lose up to 20% body weight</li>
                    <li>Weekly injectable pen</li>
                    <li>
                      Most effective by mimicking both GLP-1 + GIP hormones
                    </li>
                  </ul>
                  <div className="product-pricing">
                    <div className="price-block">
                      <span className="label text-center">FIRST MONTH:</span>
                      <span className="cricle-amount">
                        <h4 className="text-white text-center m-0 fs-25">£129</h4>
                        </span>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="quick-btn text-decoration-none metropill text-transform-uppercase text-green d-block text-center px-0"
                  >
                    Learn more
                  </a>

                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-lg-5 col-md-6">
              <div className="product-card">
                <div className="product-image">
                  <img src={Wegovy} alt="Wegovy Pen" />
                </div>
                <div className="product-content">
                  <h3>Wegovy®</h3>
                  <div className="product-tags">
                    <span className="tag">GLP-1</span>
                    <span className="tag">Semaglutide</span>
                  </div>
                  <ul className="product-features">
                    <li>Lose up to 15% body weight</li>
                    <li>Weekly injectable pen</li>
                    <li>Mimics the GLP-1 gut hormone</li>
                  </ul>
                  <div className="product-pricing">
                    <div className="price-block">
                      <span className="label text-center">FIRST MONTH:</span>
                      <span className="cricle-amount">
                        <h4 className="text-white text-center m-0 fs-25">£129</h4>
                        </span>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="quick-btn text-decoration-none metropill text-transform-uppercase d-block text-center px-0 text-green"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="stats-banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10 col-xl-11 col-lg-12">
              <div className="stats-wrapper">
                <div className="stat-item">
                  <h4 className="stat-number me-3">6x</h4>
                  <p className="stat-text">
                    more effective than traditional methods
                  </p>
                </div>
                <div className="stat-item">
                  <h4 className="stat-number me-3">20%</h4>
                  <p className="stat-text">
                    potential weight loss after a year
                  </p>
                </div>
                <div className="stat-item">
                  <h4 className="stat-number me-3">
                    29<span className="unit">kg</span>
                  </h4>
                  <p className="stat-text">
                    (4st 8lbs) lost on average after 84 weeks
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      {/* How It Works Section with Tabs */}
      <div className="how-it-works-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
            <h2 className="text-center">How Our Service Works</h2>
            </div>
            <div className="col-lg-5 col-md-6">
              {/* Add the calculator section */}

              <section className="calculator-section">
                <WeightLossCalculator />
              </section>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "step1" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("step1")}
                    role="tab"
                  >
                    Step 1
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "step2" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("step2")}
                    role="tab"
                  >
                    Step 2
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "step3" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("step3")}
                    role="tab"
                  >
                    Step 3
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div
                  className={`tab-pane ${
                    activeTab === "step1" ? "active" : ""
                  }`}
                  role="tabpanel"
                >
                  <div className="p-4 text-center">
                    <p>
                      Select your blood test either as specific tests or explore
                      through health concerns
                    </p>
                  </div>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "step2" ? "active" : ""
                  }`}
                  role="tabpanel"
                >
                  <div className="p-4 text-center">
                    <p>Schedule your convenient time for the clinic visit</p>
                  </div>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "step3" ? "active" : ""
                  }`}
                  role="tabpanel"
                >
                  <div className="p-4 text-center">
                    <p>Get your detailed results and expert analysis</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* BMI Calculator Section */}
      <section className="bmi-section-main bmi-section-main-weightloss-page my-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
            <h2 className="">BMI Calculator</h2>
            </div>
            <div className="col-lg-6">
              <section className="consultation-bmi-section">
                <Bmi
                  setIsBmi={setIsBmi}
                  onBmiValueChange={handleBMIValueChange}
                  fadeClass={fadeClass}
                  setFadeClass={setFadeClass}
                  redirectTo={'consultation/weight-loss'}
                />
              </section>
            </div>
            <div className="col-lg-6 my-auto">
            {currentWeight > 0 ? (
              <div className="graph-wrap">
                  <WeightLossGraph
                    currentWeight={currentWeight}
                    bmiCategory={currentBmiCategory}
                  />
              </div>
            ) : (
                                <div className="img-wrap-graph">
                                    <img src={WeightLossImg} alt="" />
                                </div>
                            )}
            </div>
          </div>
        </div>
      </section>

      {/* vide sec */}

      <section className="unique-objects-section">
        <div className="unique-objects-shadow"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="unique-objects-content">
                <h2>
                  <span className="highlight">The soul</span> of
                  <br />
                  unique objects
                </h2>
                <div className="stats-grid">
                  {statsData.map((stat, index) => (
                    <div key={index} className="stat-item">
                      <div className="stat-header">
                        <span className="stat-number">
                          {stat.number}
                          <span className="unit">{stat.unit}</span>
                        </span>
                        <h3 className="stat-title">{stat.title}</h3>
                      </div>
                      <p className="stat-description">{stat.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="unique-objects-image">
                <img src={weightlossbanner} alt="Ceramic Vase" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact form */}
      <div className="form_wrap">
        <h3 className="text-center">Still unsure of what test you need</h3>
        <p className="text-center">contact one of our healthcare staff to help you choose the right test for you</p>
        <p className="text-center"><a href="tel:0203 987 920"><img src={phoneIcon} alt="phone icon" /> <span>0203 987 920</span></a></p>
        <div className="form_wrap_inner">
        <BloodTestContactForm />
        </div>


      </div>

      {/* faqs */}
      <section className="faq-section">
        <div className="container">
          <h2 className="faq-title">Frequently Asked Questions</h2>
          <div className="accordion-container">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className={`accordion-item ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <button
                  className="accordion-header"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="accordion-title">{faq.question}</span>
                  <span className="accordion-icon">+</span>
                </button>
                <div className="accordion-content">
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

{/* testimonials section */}

<section className="testimonials-section">
  <div className="container-fluid p-0">
    <div className="row m-0">
      {/* <div className="col-lg-4 ps-0">
                      <div className="background-image-wrap">

                      </div>
                  </div> */}

      <div className="col-lg-12">
        <div className="testi-wrap-content-inner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="testimonials-header">
                <h2>What people say about My Private Chemist</h2>
              </div>

              <div className="testimonials-slider">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <Slider {...sliderSettings}>
                      {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-card-wrap">
                          <div className="testimonial-card">
                            <span className="quote-mark">
                              <img src={quoteIcon} alt="" />
                            </span>
                            <p className="testimonial-text">
                              {testimonial.quote}
                            </p>
                          </div>
                          <div className="testimonial-bottom-content">
                          <div className="testimonial-author">
                            <span className="author-name">
                              {testimonial.author},
                            </span>
                            <span className="author-location">
                              {testimonial.location}
                            </span>
                          </div>
                        </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>

      <NewFooter />
    </div>
  );
};

export { WeightLoss };
