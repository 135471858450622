import React from 'react'
import Checkout from './pages/checkout/Checkout';
import PageLoader from './components/PageLoader';
// import Cart from './pages/Cart';
import Cart from './pages/cart/Cart';
import AddToCart from './pages/AddToCart';
import AppointmentSuccess from './pages/AppointmentSuccess';
import AppointmentCancel from './pages/AppointmentCancel';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import DummyPage from './pages/DummyPage';
import BuildMyPkg from './pages/BuildPackage/BuildMyPkg';
import Consultation from './pages/consultation/Consultation';
import DummyConsultation from './pages/consultation/DummyConsultation';
import PreviewConsultation from './pages/consultationPreview/PreviewConsultation';
import { QuizApp } from './pages/QuizApp/QuizApp';
import { HealthConcernPage } from './pages/HealthConcernPage/HealthConcernPage';
import { CategorySingleViewPage } from './pages/CategorySingleViewPage/CategorySingleViewPage';
import { AllBloodTest } from './pages/BloodTesting/AllBloodTest';
import { BloodTestDetail } from './pages/BloodTesting/BloodTestDetail';
import ScrollTop from './components/ScrollTop';
import { WeightLoss } from './pages/WeightLoss/WeightLoss';
import ProductSelection from './pages/ProductSelection/ProductSelection';

import ProductsGuard from './middleware/ProductGuard';


const App = () => {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        <Route path='/' element={<BuildMyPkg />} />
        <Route path='/a-z-tests' element={<AllBloodTest />} />
        <Route path='/healthconcern' element={<HealthConcernPage />} />
        <Route path='/a-z-tests/:id' element={<BloodTestDetail />} />
        <Route path='/singleblood' element={<BloodTestDetail />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/loader' element={<PageLoader />} />
        <Route path='/cart/:uniqueCode' element={<AddToCart />} />
        <Route path='/cart' element={<Cart />} />
        <Route path="/appointment/success/:bookingId/:userToken" element={<AppointmentSuccess />} />
        <Route path='/appointment/cancel' element={<AppointmentCancel />} />
        <Route path='/build-my-package' element={<BuildMyPkg />} />
        <Route path='/consultation/:slug' element={<DummyConsultation />} />
        <Route path='/consultation/preview/:id' element={<PreviewConsultation />} />
        <Route path='/weight-loss' element={<WeightLoss />} />
        <Route path="/products" element={<ProductsGuard><ProductSelection /></ProductsGuard>} />
      </Routes>
    </BrowserRouter>
  )
}

export default App